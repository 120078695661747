<!-- <template>
  <v-container fluid> -->
    <!-- <button @click="generarReporte">
      Generar Reporte
    </button> -->
    <!-- <v-data-table
      :headers="headers"
      :items="listCashOperation"
      item-key="id"
      sort-by="date"
    >
    </v-data-table> -->
  <!-- </v-container>
</template> -->

<script>

import axios from 'axios'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export default {
  data: () => ({
    generalBalance: null,
    listCollection: [],
    ingresosToday: null,
    margenSubtitle: null,
    egresosToday: null,
    saldoToday: null,
    subtitle: null,
    employee: null,

    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      { text: 'Fecha', value: 'date' },
      // eslint-disable-next-line no-useless-concat
      { text: 'Descripción', value: 'description' },
      { text: 'Monto', value: 'amount' },
    ],
    reportTitle: null,
  }),

  // watch: {
  //   dialogDelete(val) {
  //     val || this.closeDelete()
  //   },
  // },

  async mounted() {
    //this.subtitle = `(desde ${this.moment(this.$route.params.fromDate).format('DD/MM/YYYY')} hasta ${this.moment(this.$route.params.toDate).format('DD/MM/YYYY')})`
    this.subtitle = `(hasta ${this.moment(this.$route.params.toDate).format('DD/MM/YYYY')})`
    this.reportTitle = 'Listado de Cobranzas'
    this.margenSubtitle = 0.37

    await this.getReport()
    await this.generarReporte()
    this.$router.push({ name: 'dashboard' })
  },
  methods: {
    async generarReporte() {
    //   const doc = new jsPDF({
    //     orientation: "portrait",
    //     unit: "px",
    //     //format: [4, 2],
    //     format: "A4",
    //     margin: { top: 20 },
    //   });
      const doc = new jsPDF('p', 'pt')

      const logo = require('../../assets/images/logos/efectivo-ya-png.png')

      const imgLogo = new Image()
      imgLogo.src = logo
      doc.addImage(imgLogo, 'PNG', 40, 40, 80, 40)

      const arregloDatos = Object(this.listCollection).map(obj => {
        const datos = [
          obj.client,
          obj.location,
          obj.phone,
          obj.quotaNumber,
          obj.amountToPay,
          obj.dueDate,

        ]

        return datos
      })

      const encabezado = ['Cliente', 'Domicilio', 'Teléfono', 'Cuota Nro.', 'Saldo', 'Vencimiento']

      const titulo = this.reportTitle
      doc.text(titulo, doc.internal.pageSize.width * 0.40, 80, {
        aling: 'center',
      })
      doc.text(this.subtitle, doc.internal.pageSize.width * 0.42, 100, {
        aling: 'center',
      })

      const emisor = `Emitido por ${this.$store.getters.usuario.name}`
      const cobrador = `Cobrador: ${this.listCollection[0].employee}`

      // const lineaInicial = 70;

      //   const lineaInicialSombreado = 80;

      const espacioEntreLineas = 20

      const fechaEmision = `Fecha de Emision: ${this.moment().format('DD/MM/YYYY')}`

      //   doc.setFillColor(240, 240, 240);
      //   doc.rect(
      //     doc.internal.pageSize.width * 0.05,
      //     lineaInicialSombreado,
      //     46,
      //     13,
      //     "F"
      //   );

      doc.text(
        fechaEmision,
        doc.internal.pageSize.width * 0.05,
        120 + espacioEntreLineas * 1,
        {
          aling: 'center',
          styles: { fontSize: 19 },
          fontSize: 1,
        },
      )

      doc.text(
        emisor,
        doc.internal.pageSize.width * 0.05,
        140 + espacioEntreLineas * 1,
        {
          aling: 'right',
          styles: { fontSize: 18 },
          fontSize: 20,
        },
      )

      doc.text(
        cobrador,
        doc.internal.pageSize.width * 0.05,
        160 + espacioEntreLineas * 1,
        {
          aling: 'right',
          styles: { fontSize: 18 },
          fontSize: 20,
        },
      )
      autoTable(doc, {
        head: [''],
        body: [''],
        margin: { top: 200 },
      
      })
      autoTable(doc, {
        head: [encabezado],
        headerStyles: { halign: 'center' },
        alternateRowStyles: { fillColor: [250, 216, 216] },
        body: arregloDatos,
        tableWidth: 575,
        columnStyles: {
          0: { cellWidth: 130, halign: 'left' },
          1: { cellWidth: 140, halign: 'left' },
          2: { cellWidth: 100, halign: 'left' },
          3: { cellWidth: 40, halign: 'right' },
          4: { cellWidth: 60, halign: 'right' },
          5: { cellWidth: 80, halign: 'right' }, // 550 tiene es el ancho maximo total en A4 vertical

          //   const encabezado = ['Cliente', 'Domicilio', 'Teléfono', 'Cuota Nro.', 'Saldo', 'Vencimiento']
        },
        theme: 'striped',
        aling: 'right',
        margin: { left: 20 },
      })



      doc.save(`Ficha - ${cobrador}.pdf`, false)
    },

    async getReport() {
      this.url = `${this.$store.getters.urlBase}/api/Report/WeeklyClientVisit`
      const options = {
        headers: {
          'content-type': 'application/json',
        },
        params: {
          fromDate: this.$route.params.fromDate, // this.moment().format('YYYY-MM-DD'),
          toDate: this.$route.params.toDate, // this.moment().format('YYYY-MM-DD'),
          personID: this.$route.params.personId,
        },

        url: this.url,
      }
      await axios
        .get(this.url, options)
      // eslint-disable-next-line no-return-assign
        .then(response => {
          this.listCollection = response.data
        })
        .catch(error => {
       
        })
    },

  },
}
</script>
